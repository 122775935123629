::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.vertical-timeline-element {
  width: 100%;
  min-height: 50px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  gap: 0px;
}
.leftVerticalTimelineElement {
  justify-content: right;
  padding-left: 40px;
}
.rightVerticalTimelineElement {
  text-align: center;
  padding-right: 40px;
}
.leftVerticalTimelineElement .vertical-timeline-element-content {
  justify-content: flex-start;
  margin-left: 0px;
  padding-left: 0px;
  max-width: fit-content;
  width: fit-content;
}
.leftVerticalTimelineElement .vertical-timeline-element-content.bounce-in {
  justify-content: left;
  text-align: left;
}

.rightVerticalTimelineElement .vertical-timeline-element-content {
  justify-content: flex-start;
  margin-left: 0px;
  padding-left: 0px;
  max-width: fit-content;
  width: fit-content;
}
.rightVerticalTimelineElement .vertical-timeline-element-content.bounce-in {
  justify-content: right;
  max-width: 50%;
  width: fit-content;
}

.vertical-timeline-element-content {
  min-width: 50%;
  padding: 0px;
  padding-top: 0px;
  display: flex;
  flex-direction: row;
  font-size: 10px;
}

.vertical-timeline-element-content.bounce-in {
  padding: 0px;
  justify-content: left;
}
.rightAlignedText {
  text-align: right;
}
.leftAlignedText {
  text-align: left;
}
.orgInput {
  width: 30rem;
}
@media screen and (max-width: 1169px) {
  .leftVerticalTimelineElement {
    width: 100%;
  }
  .vertical-timeline-element-content.bounce-in {
    max-width: fit-content;
    width: 100%;
  }
  .rightAlignedText {
    text-align: left;
  }
  .leftAlignedText {
    text-align: right;
    min-width: 50vw;
  }
  .leftVerticalTimelineElement {
    justify-content: left;
    padding-left: 0;
    padding-left: 40px;
  }
  .rightVerticalTimelineElement {
    text-align: left;
    padding-right: 0px;
    padding-left: 40px;
  }
  .vertical-timeline-element-content {
    min-width: fit-content;
    max-width: fit-content;
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .orgInput {
    width: 330px;
  }
}

/* TODO: this does not belong in the global style sheet, this can also should done using styled components */

.PhoneInputInput {
  padding: 8px;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.PhoneInputCountryIcon {
  height: 100%;
  width: 35px;
  outline: none;
}
.PhoneInputCountryIcon--border {
  box-shadow: none;
  background-color: transparent;
  outline: none;
}

.PhoneInputCountrySelect {
  border: none;
  outline: none;
}

.PhoneInputCountrySelect > * {
  background-color: white;
  outline: none;
}
